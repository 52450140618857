import styled, { css, useTheme } from "styled-components";
import { ArrowRight16StrokedIcon } from "wint-icons";
import { useAppContext } from "@common/context/AppContext";
import { Flex } from "@common/styles";
import { minDesktopCss } from "@common/styles/media";
import { BodyMediumText, ButtonDefaultText, HeadingSansSerifSmallText } from "@common/styles/typographies";
import Label from "@wint_design_system/molecules/Label/Label";
import LabelValuePair from "@wint_design_system/molecules/LabelValue/LabelValuePair";
import { useState } from "react";
import DownloadAppModal from "./DownloadAppModal";
type DownloadAppCardProps = {
  cardData: any;
  link?: any;
  cardClickId?: string;
};
const DownloadAppCard: React.FC<DownloadAppCardProps> = ({
  cardData,
  link,
  cardClickId
}) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const {
    isDesktop
  } = useAppContext();
  const theme = useTheme();
  const handleCardClick = () => {
    if (!isDesktop) {
      window.open(link, "_self");
    } else {
      setShowDownloadModal(true);
    }
  };
  const handleDownloadModalCloseClick = () => {
    setShowDownloadModal(false);
  };
  const getBgColor = () => {
    if (cardData?.deviceType === "android") {
      return theme?.colors.bg.feedback.disabled;
    }
    return theme?.colors.bg.feedbackInverse.disabledEmphasized;
  };
  const getTextColor = () => {
    if (cardData?.deviceType !== "android") {
      return theme?.colors.text.textInverse.primary;
    }
    return "";
  };
  return <>
			<Container id={cardClickId} onClick={handleCardClick} data-sentry-element="Container" data-sentry-source-file="DownloadAppCard.tsx">
				<ImageContainer $image={isDesktop ? cardData?.imageDweb?.data?.attributes?.url : cardData?.imageMweb?.data?.attributes?.url} data-sentry-element="ImageContainer" data-sentry-source-file="DownloadAppCard.tsx">
					<Flex $alignItems="center" $gap="8px" data-sentry-element="Flex" data-sentry-source-file="DownloadAppCard.tsx">
						{cardData?.tag1 ? <Label bgColor={getBgColor()} text={cardData?.tag1} webTextColor={getTextColor()} mobTextColor={getTextColor()} /> : null}
						{cardData?.tag2 ? <Label bgColor={getBgColor()} text={cardData?.tag2} webTextColor={getTextColor()} mobTextColor={getTextColor()} /> : null}
					</Flex>
				</ImageContainer>
				<DescriptionContainer data-sentry-element="DescriptionContainer" data-sentry-source-file="DownloadAppCard.tsx">
					<LabelValuePair labelText={<HeadingSansSerifSmallText>
								{cardData?.title}
							</HeadingSansSerifSmallText>} valueText={<BodyMediumText $mobColor={theme.colors.text.tertiary} $webColor={theme.colors.text.tertiary}>
								{isDesktop ? cardData?.subTextMDWeb : cardData?.subTextMWeb}
							</BodyMediumText>} data-sentry-element="LabelValuePair" data-sentry-source-file="DownloadAppCard.tsx" />
					<CTAContainer data-sentry-element="CTAContainer" data-sentry-source-file="DownloadAppCard.tsx">
						<ButtonDefaultText data-sentry-element="ButtonDefaultText" data-sentry-source-file="DownloadAppCard.tsx">Download App</ButtonDefaultText>
						<ArrowRight16StrokedIcon data-sentry-element="ArrowRight16StrokedIcon" data-sentry-source-file="DownloadAppCard.tsx" />
					</CTAContainer>
				</DescriptionContainer>
			</Container>
			<DownloadAppModal show={showDownloadModal} onClose={handleDownloadModalCloseClick} data-sentry-element="DownloadAppModal" data-sentry-source-file="DownloadAppCard.tsx" />
		</>;
};
export default DownloadAppCard;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	flex: 1;
	border: 1px solid ${({
  theme
}) => theme.colors.stroke.feedback.defaultSubtle};
	cursor: pointer;
	transition: border 0.2s ease-out;
	overflow: hidden;

	&:hover {
		border: 1px solid ${({
  theme
}) => theme.colors.stroke.feedback.default};
		transition: border 0.2s ease-out;
	}
`;
const ImageContainer = styled.div<{
  $image?: string;
}>`
	padding: 1rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: ${({
  $image
}) => $image ? `url(${$image})` : ""};
	height: 180px;

	${minDesktopCss(css`
		height: 199px;
	`)}
`;
const DescriptionContainer = styled.div`
	padding: 1rem;
`;
const CTAContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 0rem 0rem;
	margin: 1rem 0rem 0rem;
	border-top: 1px solid ${({
  theme
}) => theme.colors.stroke.card.divider};
`;